<template>
    <div class="student-products">
        <h4 class="mb-4">Профиль ученика</h4>

        <div class="widget">
            <div v-if="account" class="text-right">
                <button class="btn btn-primary" @click.prevent="reSendRegisterLink">Отправить новую ссылку на регистрацию ЛК</button>
            </div>

            <div v-if="!account" class="not-found">
                <p>Профиль ученика еще не был создан</p>
                <div><button class="btn btn-primary" @click.prevent="initForm">Создать</button></div>
            </div>

            <b-form
                v-if="account"
                class="row g-1"
                @submit.prevent="handleAccountSubmit"
                @reset="back"
            >
                <div class="col-4">
                    <b-form-group
                        label='Аватар'
                        class='col-12'
                        label-for='avatar'
                        @submit.prevent='handleAccountSubmit'
                    >
                      <div class="d-flex flex-column justify-center">
                        <img :src="account.avatar_url" alt="#" />
                        <avatar-cropper
                            uploadFileField="avatar"
                            v-model="showCropper"
                            :uploadUrl='`${baseUrl}/crm/students/upload-avatar`'
                            @completed='handleCompleted'
                        />

                        <a @click.prevent='showCropper=true' class='btn btn-primary mt-2'><i
                            class='fa fa-image mr-2'></i>Загрузить
                          аватар</a>
                      </div>
                    </b-form-group>
                </div>

                <div class="col-8">
                    <b-form-group
                        label="День рождения"
                        class='col-12'
                        label-for="birthday"
                    >
                        <!-- todo errors output -->
                        <b-form-datepicker
                             id="birthday"
                             v-model="account.birthday"
                             :value="account.birthday"
                             :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                             locale='ru'
                             :disabled="false"
                            >
                        </b-form-datepicker>
                    </b-form-group>

                    <b-form-group
                        class='col-12'
                        label='Пол:'
                        label-for="gender"
                    >
                      <b-form-select
                          id="gender"
                          placeholder='Пол'
                          v-model='account.gender'
                      >
                          <b-form-select-option v-for='(value, key) in genderList' :key='key' :value='key'>
                              {{ value }}
                          </b-form-select-option>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-lvl"
                        label="Звание:"
                        class="col-6"
                        label-for="input-lvl"
                    >
                        <b-form-select
                            :options="studentLvls"
                            v-model="account.lvl"
                        />
                    </b-form-group>
                </div>

                <b-form-group class="col-12 row justify-content-end">
                    <b-button type="submit" class="mr-2" variant="success">Сохранить</b-button>
                    <b-button type="reset" variant="primary">Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import AvatarCropper from "@/components/AvatarCropper/AvatarCropper";
import { mapGetters } from "vuex";

export default {
    name: "StudentProfile",
    components: { AvatarCropper },
    props: {
        studentId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            genderList: [
               'Женский',
               'Мужской'
            ],
            showCropper: false,
            account: null,
            studentLvls: null,
        };
    },
    computed: {
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
        }),
        baseUrl() {
          return process.env.VUE_APP_API_BASE_URL;
        },
    },
    async created() {
        await this.fetchAccountData();

        if (this.getStudentDetail.lvls) {
            this.studentLvls = this.getStudentDetail.lvls.map((v, k) => {
                return {
                    text: v,
                    value: k,
                };
            });
        }
    },
    methods: {
        async initForm() {
            this.account = {
              avatar_thumb: 'https://place-hold.it/200x200',
              avatar_url: 'https://place-hold.it/200x200',
              birthday: '',
              gender: 1,
              lvl: 0,
              student_id: this.studentId,
            };
        },
        async handleUpload(form, response) {
        },
        handleFileChange({ file, reader }) {
            this.fileName = file.name || 'unknown';
        },
        async handleCompleted({ form, request, response }) {
            const data = response.data;
            if (data.error) {
              alert('произошла ошибка при загрузке');
            } else {
              this.account.avatar_url = data.relative_url;
              this.account.avatar_thumb = data.thumb_url;
            }
        },
        async handleAccountSubmit() {
            try {
                await this.$axios.post('crm/students/update-profile', {
                    ...this.account,
                    student_id: this.studentId,
                });
                this.$toasted.success('Профиль обновлен', { position: "bottom-right" });
            } catch (ex) {
                console.log("cant update student's profile: " + ex);
                this.$toasted.error(ex, { position: "bottom-right" });
            }
        },
        async reSendRegisterLink() {
            if (!window.confirm('Чтобы отправить ссылку на регистрацию, требуется подтвердить.')) {
                return;
            }
            try {
                const { data } = await this.$axios.post(`/crm/students/re-send-account-link`, {
                    student_id: this.studentId
                });
                if (data.status === 'success') {
                    alert('Ссылка успешно отправлена');
                } else {
                    alert('Не удалось отправить ссылку');
                }
            } catch (ex) {
                console.log("can't fetch student's account: " + ex);
            }
        },
        back() {
            this.$router.back();
        },
        async fetchAccountData() {
            try {
                const { data } = await this.$axios.get(`/crm/students/website-account/${this.studentId}`);
                this.account = data.data;
            } catch (ex) {
                console.log("can't fetch student's account: " + ex);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>