<template>
    <div>
        <Preloader v-if='loader' />
        <h4 class=''>Список учеников</h4>
        <p class='p-0 mb-4'>Синхронизация производится в автоматическом режиме, ежедневно с 03:00 до 03:20 по Московскому времени</p>
        <div class='widget'>
            <div class='filter__container'>
                <div class='row mb-3 justify-content-between'>
                    <div class='input-group col-8 d-flex'>
                        <input
                            type='search'
                            class='form-control custom__search-input mr-3'
                            v-model='searchWord'
                            id='search-input'
                            placeholder="Поиск..."
                            @input="debounceSearch"
                        >
<!--                        <div>{{getStudentList[0]}}</div>-->
                          <VueMultiselect
                              class='custom__multiselect mr-3'
                              :multiple='true'
                              :close-on-select='false'
                              :clear-on-select='false'
                              :preserve-search='true'
                              selectedLabel='Выбран'
                              selectLabel='Добавить'
                              deselectLabel='Убрать'
                              placeholder='Выберите статус студента'
                              :tagable='true'
                              tagPosition='bottom'
                              label='text'
                              track-by='value'
                              :options='statusOptions'
                              v-model='selectedStatus'
                              @input='showSelectStatus'
                          >
                            <template #noOptions>Нет данных</template>
                            <template #noResult>Ничего не найдено</template>
                          </VueMultiselect>

                        <VueMultiselect
                            class='custom__multiselect mr-3'
                            :multiple='true'
                            :close-on-select='false'
                            :clear-on-select='false'
                            :preserve-search='true'
                            selectedLabel='Выбран'
                            selectLabel='Добавить'
                            deselectLabel='Убрать'
                            placeholder='Выберите возрастную группу'
                            :tagable='true'
                            tagPosition='bottom'
                            label='text'
                            track-by='value'
                            :options='getAgeGroups'
                            v-model='selectedAgeGroup'
                            @input='showSelectAgeGroups'
                        >
                            <template #noOptions>Нет данных</template>
                            <template #noResult>Ничего не найдено</template>
                        </VueMultiselect>
                        <b-checkbox class="my-auto ml-3" v-model="isOnlyNegativeSubscription" @change="showOnlyNegativeSubscription">С минусовыми абонементами</b-checkbox>
                    </div>
                    <div class='col-4 text-right'>
                        <button class='button_add custom__add-student-btn' @click='addData'>Добавить ученика</button>
                        <!--            <button class="button_sync ml-3" @click="syncronizeData">Синхронизировать</button>-->
                    </div>
                </div>
                <div class='row mb-3 justify-content-between'>
                  <div class='input-group col-8 d-flex'>
                    <b-link class="btn btn-success" :href="getLinkExcelDownload">Экспортировать в Excel</b-link>
                  </div>
                </div>
                <div class='input-group col-4'>

                </div>
            </div>
            <pagination-counter class="text-right my-3" :meta="getPageMeta"/>
            <table class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>ФИО ученика</th>
                    <th>Статус</th>
                    <th>Возраст</th>
                    <th>Возрастная группа</th>
                    <th>Уровень</th>
                    <th>ФИО родителя</th>
                    <th>Телефон родителей</th>
                    <th>Уведомления</th>
                    <th>Часовой пояс</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='student in getStudentList' :key='student.id'
                    :class="{'is-negative-subscription' : student.negative_subscription_exist}">
                    <td class="student__name">
                        {{ student.full_name }}<span
                        v-if='student.duplicate_id && student.duplicate_id > 0' title='есть дубликат' class='student__badge position-relative top-0'><img src='@/assets/icons/duplicate.svg'
                                                                                                        alt='' height='20px' width='20px'></span>

                    </td>
                    <td>
                      <span v-if="student.status === 0" class="ml-2 badge bg-warning text-info-light">Саморегестрация</span>
                      <span v-if="student.status === 1" class="ml-2 badge bg-success text-info-light">Занимается</span>
                      <span v-if="student.status === 2" class="ml-2 badge bg-danger text-info-light">Закончил обучение</span>
                      <span v-if="student.status === 3" class="ml-2 badge bg-warning text-info-light">Промо-урок</span>
                    </td>
                    <td>{{ student.age }}</td>
                    <td>{{ getAgeGroupTitle(student.age_group) }}</td>
                    <td>{{ student.experience_title }}</td>
                    <td>{{ student.parent_name }}</td>
                    <td>{{ student.parent_phone }}</td>
                    <td>{{ student.notifications_way }}</td>
                    <td>{{ student.timezone }}</td>
                    <td class='actions_container'>
                        <router-link class='leads_table_action_link btn mr-2 btn-primary'
                                     :to="{name: 'StudentDetail', params: {id: student.id}}">
                            <span class='glyphicon glyphicon-edit' />
                        </router-link>
                        <a class='btn mr-2 btn-danger' @click.prevent='deleteRow(student.id, student.full_name)'>
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div
            class='overflow-auto d-flex justify-content-between align-items-center'
             v-if="getPageMeta && getPageMeta.last_page > 1"
        >
            <b-pagination
                class="paginator my-0"
                :total-rows="getPageMeta.total"
                :per-page="getPageMeta.per_page"
                aria-controls="my-table"
                @page-click='handlePageSelect'
                v-model="currentPage"
            />
            <pagination-counter class="mr-4" :meta="getPageMeta"/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteModal from '@/components/Modal/DeleteModal';
import Preloader from '@/components/Preloader';
import VueMultiselect from 'vue-multiselect';
import dayjs from "dayjs";
import {debounce} from "debounce";
import PaginationCounter from "@/components/Pagination/PaginationCounter.vue";

export default {
    name: 'Students',
    components: {PaginationCounter, Preloader, VueMultiselect},

    async beforeRouteUpdate(to, from, next) {
      this.loader = true;
      await this.loadStudentList(to.query);
      this.loader = false;
      await next();
    },

    data() {
        return {
            baseUrl: process.env.VUE_APP_API_BASE_URL,
            isOnlyNegativeSubscription: false,
            loader: false,
            searchWord: this.$route.query.search || '',
            currentPage: this.$route.query.page || 1,
            statusOptions: [
              { value: '0', text: 'Саморегистрация' },
              { value: '1', text: 'Занимается' },
              { value: '2', text: 'Завершил обучение' },
              { value: '3', text: 'Промо урок' },
            ],
            selectedStatus: [],
            selectedAgeGroup: [],
        };
    },
    computed: {
        ...mapGetters({
            getStudentList: 'students/getStudentList',
            getPageMeta: 'students/getPageMeta',
            getAgeGroups: 'common/getAgeGroups',
        }),
        getLinkExcelDownload() {
          let queries = '';

          for (const [key, value] of Object.entries({ ...this.$route.query })) {
            if (value !== null) {
              if (queries === '') {
                queries += `?${key}=${value}`
                continue
              }
              queries += `&${key}=${value}`
            }
          }

          return `${this.baseUrl}/crm/students/export-excel${queries}`
        },
    },
    methods: {
        ...mapActions({
            loadStudentList: 'students/loadStudentList',
            findStudents: 'students/findStudents',
            deleteRecord: 'students/deleteRecord',
        }),
        deleteRow(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                }, { name: 'DeleteModal' },
            );
        },
        addData(e) {
            e.preventDefault();
            this.$router.push({ name: 'StudentAdd' });
        },
        toggleLoader() {
            this.loader = !this.loader;
        },
        getAgeGroupTitle(age_group) {
            let result = null
            this.getAgeGroups.forEach(item => {
                if (item.value === age_group) {
                    result = item.text
                }
            })

            return result
        },
        async search() {
          let query;

          if (this.searchWord && this.searchWord !== '') {
            query = Object.assign({}, this.$route.query, { search: this.searchWord, page: 1});
          } else {
            query = Object.assign({}, this.$route.query, {page: 1});
            delete query.search;
          }

          this.currentPage = 1;
          await this.$router.push({query});
        },
        async showSelectStatus() {
          const ids = this.selectedStatus.map(i => i.value).join(',');
          let query
          if (ids) {
            query = Object.assign({}, this.$route.query, {statuses: ids, page: 1});
          } else {
            query = Object.assign({}, this.$route.query, {page: 1});
            delete query.statuses;
          }
          this.currentPage = 1
          await this.$router.push({query});
        },
        async showSelectAgeGroups() {
            const ids = this.selectedAgeGroup.map(i => i.value).join(',');
            let query
            if (ids) {
                query = Object.assign({}, this.$route.query, {age_groups: ids, page: 1});
            } else {
                query = Object.assign({}, this.$route.query, {page: 1});
                delete query.age_groups;
            }
            this.currentPage = 1
            await this.$router.push({query});
        },
        async showOnlyNegativeSubscription() {
          let query
          if (this.isOnlyNegativeSubscription) {
            query = Object.assign({}, this.$route.query, {is_only_negative_subscription: this.isOnlyNegativeSubscription, page: 1});
          } else {
            query = Object.assign({}, this.$route.query, {page: 1});
            delete query.is_only_negative_subscription;
          }
          this.currentPage = 1
          await this.$router.push({query});
        },
        async handlePageSelect(bvEvent, page) {
          if (page) {
            const query = { ...this.$route.query, page: page };
            await this.$router.push({ query });
          }

          return false;
        },
        debounceSearch: debounce(async function(e) {
          const val = e.target.value;
          let query

          if (this.searchWord && this.searchWord !== '') {
            query = Object.assign({}, this.$route.query, { search: val, page: 1});
          } else {
            query = Object.assign({}, this.$route.query, {page: 1});
            delete query.search;
          }
          this.searchWord = val;
          await this.$router.push({ query });
        }, 600),
        prepareFilter() {
          if (this.$route.query.is_only_negative_subscription === 'true') {
            this.isOnlyNegativeSubscription = true
          }

          if (this.$route.query.statuses) {
            const ids = this.$route.query.statuses.split(',');
            let result = [];
            this.statusOptions.forEach((option, index) => {
              ids.forEach((statusId) => {
                if (option.value === statusId) {
                  result.push(option)
                }
              })
            })
            this.selectedStatus = [...result];
          }
        }
    },
    created() {
        this.loadStudentList(this.$route.query);
        this.prepareFilter()
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}


.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_sync {
    @extend .button_add;
    background: #4eb76e;

    &:hover {
        background: darken(#4eb76e, 10);
    }
}

.custom__multiselect {
  width: 25%;

}

.is-negative-subscription {
  background: #fae5e5;
}

.custom__search-input,
.custom__search-btn,
.custom__add-student-btn{
  height: 43px;
}

.student {
    &__name {
        position: relative;
    }

    &__badge {
        //position: absolute;
        //top: 30px;

        img {
            width: 30px;
        }
    }
}
</style>