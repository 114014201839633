<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="col-12 mt-3 mb-3">Редактирование абонемента</h4>
                <b-form
                    v-if="products"
                    class="col-12 form-product"
                    @submit.prevent="handleProductSubmit"
                    @reset="closeModal"
                >
                    <div class="product-wrap">
                        <b-form-group label="Продукт:" class="col-12" label-for="p-input-1">
                            <Multiselect
                                id="p-input-1"
                                class="custom__multiselect"
                                selectLabel="нажмите чтобы выбрать"
                                deselectLabel="нажмите чтобы убрать"
                                tagPosition="bottom"
                                :tagable="true"
                                :custom-label="labelForProduct"
                                track-by="id"
                                :disabled="true"
                                placeholder="Выберите Абонемент"
                                :options="products"
                                v-model="product"
                            >
                            </Multiselect>
                        </b-form-group>

                        <b-form-group
                            label="Количество:"
                            class="col-12"
                            label-for="p-input-2"
                        >
                            <b-form-input
                                v-if="product"
                                id="p-input-2"
                                type="number"
                                placeholder="Количество"
                                min="0"
                                required
                                v-model.number="lesson_count"
                            />
                            <b-form-input
                                v-else
                                id="p-input-2"
                                type="number"
                                placeholder="Количество"
                                min="0"
                                disabled
                            />
                        </b-form-group>
                    </div>

                    <b-form-group class="col-12 row justify-content-end m-0 mt-5">
                        <b-button type="submit" class="mr-2" variant="success">Сохранить</b-button>
                        <b-button type="reset" variant="primary">Закрыть</b-button>
                    </b-form-group>
                </b-form>

                <h5 class="col-12 mt-5 mb-3">История изменений</h5>

                <div v-if="changeHistory" class='col-12 mb-5'>
                    <div class='row-history table_head'>
                        <div class="date">Дата изменения</div>
                        <div class="creator">Изменен</div>
                        <div class="value">Значение</div>
                        <div class="lesson_date">Дата занятия</div>
                    </div>

                    <div class='history-body-scrollbar'>
                        <div v-for='ch in changeHistory' :key='ch.id' class="row-history" :class="ch.type">
                            <div class="date">{{ch.created_at}}</div>
                            <div class="creator">{{ch.creator}}</div>
                            <div class="value">{{getValue(ch.old_value, ch.new_value)}}</div>
                            <div class="lesson_date">{{ch.lesson_date}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { debounce } from "debounce";
    import Multiselect from "vue-multiselect";

    export default {
        name: 'UpdateStudentProductModal',
        components: { Multiselect },
        props: {
            actionHandler: {
                type: [Function],
                required: true
            },
            products: {
                type: Array,
                required: true
            },
            selectProduct: {
                type: Object,
                required: true
            },
            changeHistory: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                product: null,
                lesson_count: 0,
            };
        },
        methods: {
            getValue(oldValue, newValue) {
                if (!oldValue && !newValue) {
                    return '-';
                }

                if (!oldValue) {
                    return `+${newValue}`;
                }

                if (!newValue) {
                    return `+${oldValue}`;
                }

                const value = newValue - oldValue;

                if (value > 0) {
                    return `+${value}`
                }

                return value;
            },
            async handleProductSubmit() {
                if (this.product) {
                    await this.actionHandler(this.selectProduct.id, this.lesson_count);
                    this.$modal.hide('UpdateStudentProductModal');
                }
            },
            closeModal() {
                this.$modal.hide('UpdateStudentProductModal');
            },
            labelForProduct(product) {
                return `${product.title} - ${product.language}`;
            },
        },
        created() {
            this.product = this.products.find(p => p.id == this.selectProduct.product_id);
            this.lesson_count = this.selectProduct.lesson_count;
        }
    }
</script>

<style scoped lang="scss">
    .history-body-scrollbar {
        max-height: 325px;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            border-radius: 20px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: rgba(#1B3C66, 0.2);
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            height: 58px;
            background-color: #498BDF;
            border-radius: 20px;
        }
    }

    .row-history {
        padding: 3px;
        display: flex;
        border-bottom: 1px solid #eee;
        .date, .creator {
            width: 30%;
        }
        .creator {
          width: 25%;
        }
        .value {
            width: 20%;
        }
        .lesson_date {
          width: 25%;
        }
    }
</style>

